.header {
  display: flex;
  align-items: center;
  background-color: #101740;

  @media screen and (max-width: 600px) {
    & {
      padding: 10px;
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 50px;
  @media screen and (max-width: 600px) {
    & {
      margin-right: 20px;
    }
  }

  h3 {
    color: white;

    @media screen and (max-width: 600px) {
      & {
        font-size: 12px;
      }
    }
  }

  img {
    height: 50px;
  }
}

.menu {
  flex: 1;
  min-width: 0;
  background-color: #101740;
}

.content {
  padding: 60px 75px;
  min-height: calc(100vh - 134px);

  @media screen and (max-width: 600px) {
    & {
      padding: 10px 15px;
    }
  }
}

.footer {
  text-align: center;
}

.userDropdown {
  color: #fff;
  cursor: pointer;
  margin-left: auto;
}