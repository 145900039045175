.login {
  background-color: #101740;
  height: 100vh;
  color: white;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url("./images/bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(3px);
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .loginHero {
    height: 100vh;
    padding-top: 200px;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 48px;
      margin-bottom: 20px;
      font-weight: bold;
    }

    p {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    a {
      font-size: 18px;
      font-weight: 500;
    }
  }

}
