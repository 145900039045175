.qrcodePrintArea {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  text-align: center;

  h3 {
    font-weight: 500;
  }
}

.reGenerateQRCodeConfirm {
  width: 300px;
}

.qrCodeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  h5 {
    font-weight: 500;
  }
}
