.search {
  background-color: #101740;
  padding: 30px;
  border-radius: 16px;
  margin-top: 10px;
  margin-bottom: 10px;

  h3 {
    font-size: 20px;
    font-weight: bold;
    color: white;
    margin-bottom: 15px;
    @media screen and (max-width: 600px) {
      & {
        font-size: 14px;
      }
    }
  }

  .searchActions {
    display: flex;
    gap: 10px;

    @media screen and (max-width: 600px) {
      & {
        flex-direction: column;
      }
    }

    .inputs {
      width: 100%;
      display: flex;
      gap: 5px;

      @media screen and (max-width: 600px) {
        & {
          flex-direction: column;
        }
      }

      .select {
        background-color: white;
        padding: 10px 15px;
        height: 52px;
        border-radius: 0;
        @media screen and (max-width: 600px) {
          & {
            border-radius: 16px;
            height: 40px;
            :global(.ant-select-selection-placeholder) {
              font-size: 12px;
            }
          }
        }
      }

      .formItem {
        flex: 1;

        &:first-child {
          :global(.ant-select) {
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
          }
        }

        &:last-child {
          :global(.ant-select) {
            border-top-right-radius: 16px !important;
            border-bottom-right-radius: 16px;
          }
        }
      }
    }

    .searchBtn {
      width: 100px;
      height: 52px;
      @media screen and (max-width: 600px) {
        & {
          width: 100%;
          height: 40px;
        }
      }
    }
  }
}

.calendar {
  display: flex;
  margin-top: 10px;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 16px;
  flex-wrap: wrap;

  @media screen and (max-width: 600px) {
    & {
      flex-direction: column;
    }
  }

  .calendarDate {
    width: calc(100% / 7);

    &:nth-child(n + 8) {
      border-top: 1px solid #e5e7eb;
    }

    &:not(:last-child) {
      border-right: 1px solid #e5e7eb;

      @media screen and (max-width: 600px) {
        border-right: none;
      }
    }

    @media screen and (max-width: 600px) {
      &:not(:first-child) {
        .header {
          border-top: 1px solid #e5e7eb;
        }
      }
    }

    .header {
      font-size: 18px;
      font-weight: 600;
      padding: 20px;
      border-bottom: 1px solid #e5e7eb;
      background-color: white;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .slots {
      padding: 20px 12px 20px 8px;
      .slot {
        background-color: #0ea5e91a;
        border-radius: 4px;
        margin-bottom: 10px;
        border-left: 3px solid #0ea5e9;
        padding: 8px;
        color: #0369a1;
        font-size: 12px;

        :global(.ant-btn) {
          background-color: #4b5dc4;
          margin-top: 5px;
        }

        .branch {
          font-weight: 600;
          margin-top: 5px;
        }
      }
      .notAvailable {
        background-color: #f5f5f5;
        :global(.ant-btn) {
          background-color: transparent;
          color: #475569;
          border-color: #475569;
        }
      }
    }
  }
}

.weekNavigation {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
  }
}

.redButton {
  background-color: #ff4d4f !important; // This is Ant Design's default red
  border-color: #ff4d4f !important;
  color: #ffffff !important;
  &:hover {
    background-color: darken(#ff4d4f, 5%) !important;
    border-color: darken(#ff4d4f, 5%) !important;
  }
}
