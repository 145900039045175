.resetPassword {
  background-color: #101740;
  height: 100vh;
  color: white;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url("./images/bg.jpg");

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(3px);
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .resetPasswordHero {
    height: 100vh;
    padding-top: 200px;

    h1 {
      font-size: 48px;
      margin-bottom: 20px;
      font-weight: bold;
    }

    p {
      font-size: 18px;
      font-weight: 500;
    }
  }

  .resetPasswordFormWrapper {
    padding: 20px 0px;

    .resetPasswordForm {
      height: calc(100vh - 40px);

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #fff;
      border-radius: 8px;

      .logo {
        width: 75%;
        margin-bottom: 5%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        color: black;
        font-weight: 600;
        margin-top: 20px;
        font-size: 24px;
      }

      .formWrapper {
        width: 60%;
        margin-top: 40px;
      }
    }

    @media screen and (max-width: 600px) {
      & {
        padding: 20px 20px;
      }
    }
  }
}
