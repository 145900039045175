.calendar {
  display: flex;
  border: 1px solid #e5e7eb;
  border-radius: 16px;
  @media screen and (max-width: 600px) {
    & {
      flex-direction: column;
    }
  }
  .calendarDay {
    flex: 1;

    @media screen and (max-width: 600px) {
      &:not(:first-child) .header {
        border-top: 1px solid #e5e7eb;
      }
    }

    &:not(:last-child) {
      border-right: 1px solid #e5e7eb;

      @media screen and (max-width: 600px) {
        border: none;
      }
    }

    .header {
      border-bottom: 1px solid #e5e7eb;
      padding: 15px;
      h3 {
        font-weight: bold;
      }
    }

    .slots {
      padding: 15px;

      .slotWrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        justify-content: center;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        .slot {
          border: 1px solid #e5e7eb;
          border-radius: 4px;
          padding: 5px 8px;
          display: flex;
          justify-content: center;
          flex: 1;
        }

        &.available {
          .slot {
            background-color: #88cf88;
            color: black;
          }
        }

        &.unavailable {
          .slot {
            background-color: #eb7979;
            color: black;
          }
        }
      }
    }
  }
}

.calendarTitle {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
}
